import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { TokenProvider } from './TokenContext';
import { UsersProfileIdProvider } from './UsersProfileIdContext';
import { TopMessageProvider } from './TopMessageContext';
import { StoredProfilesProvider } from './StoredProfilesContext';

import Loading from './Loading';

import CorporateHome from './corporate/Home';
import About from './corporate/About';
import Contact from './corporate/Contact';
import StudentsAndInstructors from './corporate/StudentsAndInstructors';
import NotFound from './NotFound';

// import Home from './Home';
// import Explore from './Explore';
// import Profile from './users/Profile';
// import SignUp from './users/SignUp';
// import LogIn from './users/LogIn';
// import ForgotPassword from './users/ForgotPassword';
// import ForgotPasswordChange from './users/ForgotPasswordChange';
import EmailVerification from './users/EmailVerification';
// import ProfileCreate from './users/ProfileCreate';
// import SelectInterests from './users/SelectInterests';

const Terms = React.lazy(() => import('./corporate/legal/Terms'));
const DataPolicy = React.lazy(() => import('./corporate/legal/DataPolicy'));

export default function App() {
	return (
		<TokenProvider>
			<UsersProfileIdProvider>
				<TopMessageProvider>
					<StoredProfilesProvider>
						<Router>
							<Suspense fallback={<Loading />}>
								<Switch>
									<Route exact path='/' render={() => <CorporateHome />} />
									<Route exact path='/about' render={() => <About />} />
									<Route exact path='/contact' render={() => <Contact />} />
									<Route
										exact
										path='/for-students-instructors'
										render={() => <StudentsAndInstructors />}
									/>
									<Route exact path='/legal/terms' render={() => <Terms />} />
									<Route
										exact
										path='/legal/data-policy'
										render={() => <DataPolicy />}
									/>
									{/* 
									<Route exact path='/home' render={() => <Home />} />
									
									<Route exact path='/explore' render={() => <Explore />} />
									
									<Route
										exact
										path='/profiles/create'
										render={() => <ProfileCreate />}
									/>
									
									<Route
										exact
										path='/profiles/create/interests'
										render={() => <SelectInterests />}
									/>
									
									<Route
										exact
										path='/profiles/:profileId'
										render={() => <Profile />}
									/>
									<Route exact path='/signup' render={() => <SignUp />} />
									<Route exact path='/login' render={() => <LogIn />} />
									 
									<Route
										exact
										path='/password/reset'
										render={() => <ForgotPassword />}
									/>
									
									<Route
										exact
										path='/password/reset/confirm/:idBase64/:token'
										render={() => <ForgotPasswordChange />}
									/> */}
									<Route
										exact
										path='/emailverification/:idBase64/:token'
										render={() => <EmailVerification />}
									/>
									<Route path='/' render={() => <NotFound />} />
								</Switch>
							</Suspense>
						</Router>
					</StoredProfilesProvider>
				</TopMessageProvider>
			</UsersProfileIdProvider>
		</TokenProvider>
	);
}
