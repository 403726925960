import Message from './Message';

import './TopMessage.css';

export default function TopMessage({ hidden, type, text }) {
	return (
		<div className={hidden ? 'TopMessage hidden' : 'TopMessage'}>
			<Message type={type} text={text} />
		</div>
	);
}
