import { useEffect } from 'react';

import CorporatePage from './CorporatePage';

export default function About() {
	useEffect(() => {
		document.title = 'About Intellyon';
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0)
	  }, [])

	return (
		<CorporatePage>
			<section className='readableWidth'>
				<h1>About Intellyon</h1>
				<p>
					Intellyon enables you to quickly find and share insights, journeys,
					tips and experiences in the form of images, videos, drawings and
					programming code. Content is structured under academic, technology,
					personal development, and language topics.
				</p>
				<p>
					Your experience on Intellyon is personalized and relevant to you based
					on your profile, interests, skills, people you follow, as well as
					topics and hashtags you follow.
				</p>
				<hr />
				<h3>Journeys</h3>
				<p>
					On Intellyon, a journey can be a project, reflections, preparing for
					an exam, progress, trip, learning a new language, and more. Posts can
					be created as steps as part of a larger journey.
				</p>
				<h3>Requests</h3>
				<p>
					If you can't find what you're looking for or have an unanswered
					question, you can submit a request to the community for a post that
					will hopefully address your need.
				</p>
				<h3>Informative</h3>
				<p>
					If you discover a post that you think is informative and want others
					to see it, you can mark it as informative by tapping the lightbulb.
				</p>
				<h3>Upvotes</h3>
				<p>
					Upvotes are similar to informative reactions, but for requests. A
					request with many upvotes will be more likely to be presented to
					people who may be able to respond to it.
				</p>
				<h3>Current Limits</h3>
				<ul>
					<li>
						<b>Journeys</b>
						<br />
						Maximum 366 steps
					</li>
					<li>
						<b>Videos</b>
						<br />
						Duration: Minimum 1 second, maximum 10 minutes
						<br />
						Resolution: Maximum 1080p (Full HD / FHD), larger videos are resized
						when uploaded
					</li>
					<li>
						<b>Image Dimensions</b>
						<br />
						Images larger than 2048 are resized to 2048 (width and height) when
						uploaded
					</li>
					<li>
						<b>Descriptions and Comments</b>
						<br />
						Maximum 500 characters
					</li>
					<li>
						<b>Programming Code</b>
						<br />
						Maximum 1500 characters
					</li>
				</ul>
			</section>
		</CorporatePage>
	);
}
