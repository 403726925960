import { useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function NotFound() {
	useEffect(() => {
		document.title = 'Intellyon';
	}, []);

	return (
		<section className='readableWidth'>
			<h1>Not Found</h1>
			<p>The page you're looking for doesn't exist.</p>
			<p>
				<Link to='/'>Go home</Link>
			</p>
		</section>
	);
}
