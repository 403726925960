import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CancelToken, isCancel } from 'axios';

import api from '../api';
import { useSetTopMessage } from '../TopMessageContext';

import CorporatePage from './CorporatePage';
import Message from '../Message';

import './Contact.css';

export default function Contact() {
	const history = useHistory();
	const setTopMessage = useSetTopMessage();

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [subject, setSubject] = useState('');
	const [message, setMessage] = useState('');

	const [cancelSource, setCancelSource] = useState(null);
	const [pending, setPending] = useState(false);
	const [problem, setProblem] = useState(null);

	useEffect(() => {
		return () => {
			cancelSource?.cancel();
		};
	}, [cancelSource]);

	useEffect(() => {
		window.scrollTo(0, 0)
	  }, [])

	useEffect(() => {
		document.title = 'Contact Us';
	}, []);

	const submit = async () => {
		if (pending) {
			return;
		}

		setProblem(null);
		setPending(true);

		const source = CancelToken.source();
		setCancelSource(source);

		try {
			const { data } = await api.post(
				'/accounts/user-message/',
				{
					name: name || null,
					email,
					subject,
					message,
				},
				{
					cancelToken: source.token,
				}
			);
			setTopMessage(data.message);
			history.push('/');
		} catch (error) {
			if (!isCancel(error)) {
				const problem = error.response?.data.error;
				if (problem) {
					setProblem(problem);
				} else {
					setProblem({
						message: error.message,
					});
				}
				setPending(false);
			}
		}
	};

	let extraInfo;
	if (subject === 'Creator Access') {
		extraInfo =
			'Please provide info in support of your request for Creator Access';
	} else if (subject === 'Bug') {
		extraInfo =
			'Please include steps and other information for reproducing the bug';
	}

	return (
		<CorporatePage>
			<section className='readableWidth'>
				<h1>Contact Us</h1>
				{problem && problem.field == null ? (
					<Message type='failure' text={problem.message} />
				) : null}
				<div className='Contact-form'>
					<label>
						<span>Name</span>
						<input
							type='text'
							value={name}
							onChange={(e) => setName(e.target.value)}
							maxLength={50}
						/>
					</label>
					{problem && problem.field === 'name' ? (
						<Message type='failure' text={problem.message} />
					) : null}
					<label>
						<span>
							Email<span className='Contact-required'>*</span>
						</span>
						<input
							type='email'
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							maxLength={254}
						/>
					</label>
					{problem && problem.field === 'email' ? (
						<Message type='failure' text={problem.message} />
					) : null}
					<label>
						<span>
							Subject<span className='Contact-required'>*</span>
						</span>
						<select
							value={subject}
							onChange={(e) => setSubject(e.target.value)}
						>
							<option disabled style={{ display: 'none' }}></option>
							<option>Creator Access</option>
							<option>Question</option>
							<option>Feedback</option>
							<option>Bug</option>
							<option>Suggestion</option>
							<option>Content</option>
							<option>Other</option>
						</select>
					</label>
					{problem && problem.field === 'subject' ? (
						<Message type='failure' text={problem.message} />
					) : null}
					<label>
						<span>
							Message<span className='Contact-required'>*</span>
							{extraInfo ? (
								<>
									<br />
									<span style={{ fontWeight: 'normal' }}>{extraInfo}</span>
								</>
							) : null}
						</span>
						<textarea
							value={message}
							onChange={(e) => setMessage(e.target.value)}
							rows={6}
							maxLength={500}
						/>
					</label>
					{problem && problem.field === 'message' ? (
						<Message type='failure' text={problem.message} />
					) : null}
					<p>
						<span className='Contact-required'>*</span> Required field.
					</p>
					<button
						disabled={!email || !subject || !message || pending}
						className='button'
						style={{ maxWidth: '200px' }}
						onClick={submit}
					>
						Submit
					</button>
				</div>
			</section>
		</CorporatePage>
	);
}
