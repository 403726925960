import { Link } from 'react-router-dom';

import './Header.css';

export default function Header() {
	return (
		<nav className='Header'>
			<div className='readableWidth'>
				<Link to='/' className='Header-title'>
					Intellyon
				</Link>
			</div>
		</nav>
	);
}
