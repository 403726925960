import { useState, useCallback, useContext, createContext } from 'react';

const UsersProfileIdContext = createContext();
const SetUsersProfileIdContext = createContext();

export function useUsersProfileId() {
	return useContext(UsersProfileIdContext);
}

export function useSetUsersProfileId() {
	return useContext(SetUsersProfileIdContext);
}

export function UsersProfileIdProvider({ children }) {
	const [usersProfileId, setUsersProfileId] = useState(() =>
		localStorage.getItem('profileId')
	);

	const setUsersProfileIdCallback = useCallback((id) => {
		setUsersProfileId(id);
		if (id) {
			localStorage.setItem('profileId', id);
		} else {
			localStorage.removeItem('profileId');
		}
	}, []);

	return (
		<UsersProfileIdContext.Provider value={usersProfileId}>
			<SetUsersProfileIdContext.Provider value={setUsersProfileIdCallback}>
				{children}
			</SetUsersProfileIdContext.Provider>
		</UsersProfileIdContext.Provider>
	);
}
