import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import './Loading.css';

export default function Loading() {
	return (
		<section className='Loading'>
			<FontAwesomeIcon icon={faSpinner} spin size='2x' />
			Loading...
		</section>
	);
}
