import { useState, useCallback, useContext, createContext } from 'react';

import api from './api';

const SetTokenContext = createContext();

export function useSetToken() {
	return useContext(SetTokenContext);
}

export function TokenProvider({ children }) {
	const [, setToken] = useState(() => {
		const token = localStorage.getItem('token');
		api.defaults.headers.common['Authorization'] = token
			? `Bearer ${token}`
			: null;
		return token;
	});

	const setTokenCallback = useCallback((token) => {
		setToken(token);
		api.defaults.headers.common['Authorization'] = token
			? `Bearer ${token}`
			: null;
		if (token) {
			localStorage.setItem('token', token);
		} else {
			localStorage.removeItem('token');
		}
	}, []);

	return (
		<SetTokenContext.Provider value={setTokenCallback}>
			{children}
		</SetTokenContext.Provider>
	);
}
