import { useEffect } from 'react';

import CorporatePage from './CorporatePage';

export default function StudentsAndInstructors() {
	useEffect(() => {
		document.title = 'Intellyon for Students and Instructors';
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0)
	  }, [])

	return (
		<CorporatePage>
			<section className='readableWidth'>
				<h1>A few examples on using Intellyon</h1>
				<h3>Instructors can:</h3>
				<ul>
					<li>
						Share tips, experiences, hacks with peers and other students around
						the world
					</li>
					<li>
						Learn from peers around the world to advance their professional
						development and discover new ways to keep students engaged
					</li>
					<li>Develop deeper interactions with students (online)</li>
				</ul>
				<h3>Students can:</h3>
				<ul>
					<li>
						Share their exam preparation journeys with peers which would help
						with informing, supporting and inspiring each other
					</li>
					<li>Follow and learn from people in professions of their dreams</li>
					<li>
						Showcase projects they're working on and get feedback from peers and
						teachers
					</li>
					<li>Share journeys of their transition to college</li>
				</ul>
			</section>
		</CorporatePage>
	);
}
