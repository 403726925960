import { useState, useCallback, useContext, createContext } from 'react';

import TopMessage from './TopMessage';

const SetTopMessageContext = createContext();

export function useSetTopMessage() {
	return useContext(SetTopMessageContext);
}

let messageHideHandle;

export function TopMessageProvider({ children }) {
	const [message, setMessage] = useState(null);
	const [messageHidden, setMessageHidden] = useState(true);

	const setTopMessageCallback = useCallback(
		(message, type = 'success', seconds = 3) => {
			setMessage({
				type: type,
				text: message,
			});
			setMessageHidden(false);
			if (messageHideHandle) {
				clearTimeout(messageHideHandle);
			}
			messageHideHandle = setTimeout(() => {
				setMessageHidden(true);
			}, seconds * 1000);
		},
		[]
	);

	return (
		<SetTopMessageContext.Provider value={setTopMessageCallback}>
			<TopMessage
				hidden={messageHidden}
				type={message?.type}
				text={message?.text}
			/>
			{children}
		</SetTopMessageContext.Provider>
	);
}
