import axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';

export default applyCaseMiddleware(
	axios.create({
		baseURL: process.env.REACT_APP_API_PATH,
	}),
	{
		ignoreHeaders: true,
	}
);
