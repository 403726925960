import { useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { CancelToken, isCancel } from 'axios';

import api from '../api';
import { useSetTopMessage } from '../TopMessageContext';

import Loading from '../Loading';

export default function EmailVerification() {
	const history = useHistory();
	const setTopMessage = useSetTopMessage();
	const { idBase64, token } = useParams();

	const verify = useCallback(
		async (source) => {
			try {
				const {
					data: { message },
				} = await api.get(`/emailverification/${idBase64}/${token}/`, {
					cancelToken: source.token,
					headers: {
						Authorization: null,
					},
				});
				setTopMessage(message);
			} catch (error) {
				if (!isCancel(error)) {
					const message = error.response?.data.error?.message ?? error.message;
					setTopMessage(message, 'failure');
				}
			}
			history.push('/');
		},
		[history, setTopMessage, idBase64, token]
	);

	useEffect(() => {
		const source = CancelToken.source();
		verify(source);

		return () => {
			source.cancel();
		};
	}, [verify]);

	return <Loading />;
}
