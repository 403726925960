import { useState, useContext, createContext } from 'react';

const StoredProfilesContext = createContext();
const SetStoredProfilesContext = createContext();

export function useStoredProfiles() {
	return useContext(StoredProfilesContext);
}

export function useSetStoredProfiles() {
	return useContext(SetStoredProfilesContext);
}

export function StoredProfilesProvider({ children }) {
	const [storedProfiles, setStoredProfiles] = useState({});

	return (
		<StoredProfilesContext.Provider value={storedProfiles}>
			<SetStoredProfilesContext.Provider value={setStoredProfiles}>
				{children}
			</SetStoredProfilesContext.Provider>
		</StoredProfilesContext.Provider>
	);
}
