import Header from './Header';
import Footer from './Footer';

import './CorporatePage.css';

export default function CorporatePage({ children }) {
	return (
		<>
			<Header />
			<main className='CorporatePage-main'>{children}</main>
			<Footer />
		</>
	);
}
