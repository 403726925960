import { Link } from 'react-router-dom';

import './Footer.css';

export default function Footer() {
	return (
		<footer className='Footer'>
			<div className='Footer-container readableWidth'>
				<Link to='/about'>About Intellyon</Link>
				<Link to='/contact'>Contact Us</Link>
				<Link to='/legal/data-policy'>Privacy Policy</Link>
				<Link to='/legal/terms'>Terms of Service</Link>
				<Link to='/for-students-instructors'>For Students and Instructors</Link>
				<span>&copy; 2021 Intellyon</span>
			</div>
		</footer>
	);
}
