import { useEffect } from "react";
import { Link } from "react-router-dom";

import "./Home.css";

import Footer from "./Footer";

import icon from "./Icon-512.png";
import newMediaPostImage from "./screenshots/newMediaPost.jpeg";
import postDetailImage from "./screenshots/newPostDetail.PNG";
import newCanvasPostImage from "./screenshots/newCanvasPost.jpeg";
import requestPageImage from "./screenshots/requests.jpeg";
import explorePageImage from "./screenshots/explore.jpeg";
import searchPageImage from "./screenshots/search.jpeg";
import appStoreBadge from "./Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";

// https://github.com/yifaneye/react-gallery-carousel
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";

export default function Home() {
  useEffect(() => {
    document.title = "Intellyon";
  }, []);

  // this makes the page start at the top
  useEffect(() => {
		window.scrollTo(0, 0)
	  }, [])

  const images = [
    {
      src: postDetailImage,
      alt: "Post detail with journey view",
    },
    {
      src: newMediaPostImage,
      alt: "New media post of a journey to become a cook",
    },
    {
      src: newCanvasPostImage,
      alt: "Canvas drawing demonstrating use of shapes and text tool",
    },
    {
      src: requestPageImage,
      alt: "Personalized request page view",
    },
    {
      src: explorePageImage,
      alt: "Explore page showing different categories",
    },
    {
      src: searchPageImage,
      alt: "Search view on explore page",
    },
  ];

  return (
    <>
      <nav className="Header Home-header">
        <div className="Home-header-title">
          <Link to="/" className="Header-title">
            Intellyon
          </Link>
        </div>
        <div className="Home-header-spacer"></div>
      </nav>
      <main className="Home-main">
        <div className="Home-side">
          <div className="Home-screenshot-container">
            <Carousel
              images={images}
              hasThumbnails={false}
              isAutoPlaying={true}
              autoPlayInterval={7500}
              hasIndexBoard={false}
              className="Home-screenshot"
            />
          </div>
        </div>
        <section className="Home">
          <img src={icon} className="Home-icon" alt="Intellyon Logo" />
          <h1>Intellyon</h1>
          <div className="Home-body">
            <p style={{ marginTop: 0 }}>
              Knowledge is power! Learning from successes, mistakes and stories
              is so effective and important. <br/>Intellyon is not just another social media platform, 
              it&rsquo;s an online community of people who share knowledge in an
              engaging, visual and quick manner.
            </p>
            <div className="Home-box">
              <ul>
                <li style={{fontSize: "larger"}}>Learn by following journeys of peers and experts</li>
                <li style={{fontSize: "larger"}}>Expand your knowledge on topics of your interest</li>
                <li style={{fontSize: "larger"}}>Showcase your talent and skills</li>
                <li style={{fontSize: "larger"}}>Ask questions or submit requests for insights</li>
                <li style={{fontSize: "larger"}}>Answer questions or respond to insight requests</li>
              </ul>
            </div>
            <p>
              Intellyon is for anyone aged 13 years or older, whether a student,
              educator, professional, or retiree.
            </p>
            <p>The first version of the app is currently on iOS.</p>
          </div>
          <a href="https://apps.apple.com/us/app/id1502921840">
            <img
              src={appStoreBadge}
              alt="Download on the App Store"
              style={{ height: "4em" }}
            />
          </a>
        </section>
      </main>
      <Footer />
    </>
  );
}
